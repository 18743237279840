.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery > .container {
  columns: 3 auto;
  margin: 50px 20px;
  column-gap: 10px;
}
.gallery > .container > img {
  width: 100%;
  border: 1px solid white;
  margin: 2.5px;
}
