.mars {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
  background: url("../Assets/mars/bg.jpg") no-repeat center;
  background-size: cover;
}
.mars > .header {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 100px;
}
.mars > .header > img {
  width: 400px;
}
.mars > .header > .left {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.mars > .header > .left > .head {
  font-size: 100px;
  font-weight: 700;
  font-family: "Russo One", sans-serif;
  letter-spacing: 2px;
}
.mars > .header > .left > .desc {
  width: 350px;
  font-size: 22px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.mars > .touristHeader,
.mars > .aboutHeader,
.mars > .factsHeader {
  font-family: "Russo One", sans-serif;
  font-size: 65px;
  font-weight: bold;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mars > .tourist1Desc,
.mars > .touristDesc,
.mars > .aboutDesc,
.mars > .factsDesc {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.mars > .tourist1Desc > div,
.mars > .aboutDesc > div {
  width: 600px;
  font-size: 22px;
  line-height: 32px;
}
.mars > .touristDesc > div {
  font-size: 28px;
  line-height: 36px;
  width: 600px;
}
.mars > .tourist1Desc > img,
.mars > .touristDesc > img,
.mars > .aboutDesc > img,
.mars > .factsDesc > img {
  width: 600px;
}
.mars > .factsDesc > div {
  width: 1000px;
  margin-bottom: 100px;
  font-size: 22px;
  line-height: 32px;
}
.mars > .touristHeader1 {
  font-size: 65px;
  font-size: 45px;
  font-weight: bold;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mars > .touristHeader1 > div {
  font-family: "Russo One", sans-serif;
}
.mars > .touristHeader1 > div {
  width: 1200px;
}
.mars > .factsDesc > div > li {
  margin-bottom: 5px;
}
