/* #00825d
#396800
#1b0068
#613288 */

.uranus {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #04364a;
  /* background-color: #613288; */
  color: #93ced5;
}
.uranus > .header {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.uranus > .header > img {
  width: 350px;
}
.uranus > .header > .left {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.uranus > .header > .left > .head {
  font-size: 100px;
  font-weight: 600;
  letter-spacing: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, Courier, monospace,
    sans-serif;
}
.uranus > .header > .left > .desc {
  width: 350px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.uranus > .touristHeader,
.uranus > .aboutHeader,
.uranus > .factsHeader {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 65px;
  font-weight: bold;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uranus > .touristDesc,
.uranus > .aboutDesc,
.uranus > .factsDesc {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.uranus > .touristDesc > div,
.uranus > .aboutDesc > div {
  width: 600px;
  font-size: 22px;
  line-height: 32px;
}
.uranus > .touristDesc > img,
.uranus > .aboutDesc > img {
  width: 600px;
}

.uranus > .factsDesc > div {
  width: 1000px;
  margin-bottom: 100px;
  font-size: 22px;
  line-height: 32px;
}
.uranus > .factsDesc > div > li {
  margin-bottom: 10px;
}

.uranus > .boxes {
  display: grid;
  grid-template-columns: 400px 400px;
  align-items: flex-start;
  justify-content: center;
  margin-top: 70px;
  gap: 30px;
}
.uranus > .boxes > .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.uranus > .boxes > .box > .head {
  font-size: 26px;
  font-weight: 700;
}
.uranus > .boxes > .box > .desc {
  border: 2px solid white;
  padding: 20px 10px;
  font-size: 20px;
  line-height: 26px;
  height: 300px;
  display: flex;
  align-items: center;
  background-color: #44c6d454;
  color: rgb(203, 240, 255);
}
