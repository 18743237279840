.trending {
  display: grid;
  grid-template-columns: auto auto auto;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding-top: 50px;
}
.trending > .item {
  border: 2px solid white;
  padding: 20px 10px 10px;
  background-color: #17171ba9;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
  gap: 10px;
}
.trending > .item > img {
  height: 225px;
  width: 350px;
}
.trending > .item > div {
  font-size: 24px;
  font-weight: 700;
}
