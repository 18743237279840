.about {
  display: flex;
  justify-content: center;
  padding: 100px 0;
}

.about > .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #17171ba9;
  padding: 30px;
  border-radius: 20px;
  gap: 20px;
}
.about > .container > .text {
  color: white;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.about > .container > .desc {
  width: 570px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  font-family: "Inter", sans-serif;
}
.about > .container > .contact {
  text-align: left;
}
