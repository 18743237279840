.layout {
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(65.99deg, #000 0, transparent 99.04%);
}
.nav {
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.753);
  color: white;
  padding: 0px 30px 0px 20px;
}
.navT {
  background-color: rgba(0, 0, 0, 0);
}
.nav > .logo {
  display: flex;
  align-items: center;
  gap: 2px;
  text-decoration: none;
}
.nav > .logo > img {
  height: 60px;
}
.nav > .logo > div {
  color: white;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  letter-spacing: 2px;
}
.nav > .links {
  display: flex;
  gap: 30px;
  align-items: center;
}
.nav > .links > .link {
  text-decoration: none;
  color: #919191;
  font-weight: 500;
  font-size: 24px;
  transition: 0.5s color;
  letter-spacing: 1px;
}
.nav > .links > .link:hover {
  color: white;
}
.nav > .links > .linkactive {
  text-decoration: none;
  color: white;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 1px;
}
.c {
  color: white;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  margin-top: 50px;
  /* position: fixed;
  right: 0;
  bottom: 0; */
}
