.saturn {
  height: 100vh;
  width: 100vw;
  background: #383434;
  overflow-y: auto;
  overflow-x: hidden;
}
.saturn > .header {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 400px;
  gap: 100px;
}
.saturn > .header > img {
  height: 400px;
}
.saturn > .header > div > .text {
  align-self: flex-end;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 100px;
  font-weight: bold;
  color: #f57a3c;
}
.saturn > .header > div > .desc {
  color: #f4b183;
  font-size: 24px;
  width: 400px;
  text-align: center;
}

.saturn > .touristHeader,
.saturn > .aboutHeader,
.saturn > .factsHeader {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 65px;
  font-weight: bold;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f57a3c;
}
.saturn > .touristDesc,
.saturn > .aboutDesc,
.saturn > .factsDesc {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #ffdeca;
}
.saturn > .touristDesc > div,
.saturn > .aboutDesc > div {
  width: 600px;
  font-size: 22px;
  line-height: 32px;
}
.saturn > .touristDesc > img,
.saturn > .aboutDesc > img,
.saturn > .factsDesc > img {
  width: 600px;
}
.saturn > .factsDesc > div {
  width: 1000px;
  margin-bottom: 100px;
  font-size: 22px;
  line-height: 32px;
}
.saturn > .touristHeader1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 45px;
  font-weight: bold;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f57a3c;
}
.saturn > .touristHeader1 > div {
  width: 1200px;
}
