.moon {
  height: 100vh;
  width: 100vw;
  background-color: black;
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
}
.moon > .header {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  height: 400px;
  gap: 100px;
}
.moon > .header > .text {
  align-self: flex-end;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 150px;
  font-weight: bold;
}
.moon > .header > img {
  align-self: center;
  height: 270px;
}
.moon > .shortdesc {
  display: flex;
  justify-content: center;
}
.moon > .shortdesc > div {
  border: 2px solid white;
  padding: 20px 20px;
  border-radius: 50px;
  font-size: 22px;
  width: 1125px;
  text-align: center;
  line-height: 30px;
  margin-top: 20px;
}

.moon > .touristHeader,
.moon > .waterHeader,
.moon > .weatherHeader,
.moon > .carnivalHeader,
.moon > .dykHeader,
.moon > .factsHeader {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.moon > .touristHeader > img,
.moon > .waterHeader > img,
.moon > .weatherHeader > img,
.moon > .carnivalHeader > img,
.moon > .dykHeader > img,
.moon > .factsHeader > img {
  height: 100px;
}
.moon > .touristHeader > div,
.moon > .waterHeader > div,
.moon > .weatherHeader > div,
.moon > .carnivalHeader > div,
.moon > .dykHeader > div,
.moon > .factsHeader > div {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 50px;
  font-weight: bold;
}
.moon > .touristDesc,
.moon > .waterDesc,
.moon > .weatherDesc,
.moon > .carnivalDesc,
.moon > .factsDesc {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.moon > .touristDesc > div,
.moon > .waterDesc > div,
.moon > .weatherDesc > div,
.moon > .carnivalDesc > div,
.moon > .factsDesc > div {
  width: 600px;
  font-size: 22px;
  line-height: 32px;
}
.moon > .touristDesc > img,
.moon > .waterDesc > img,
.moon > .weatherDesc > img,
.moon > .carnivalDesc > img,
.moon > .factsDesc > img {
  width: 600px;
}
.moon > .dykDesc {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.moon > .dykDesc > div {
  width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.moon > .dykDesc > div > li {
  font-size: 22px;
  line-height: 32px;
}
