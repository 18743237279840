.liveview {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(#000101, #061a37);
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
}
.liveview > .container {
  width: 100vw;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}
