/* #9689ce 
#7dccc9
#cca97d
#e8a5cd
*/
.neptune {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #93ced5;
  /* background-color: #613288; */
  color: #3551cc;
}
.neptune > .header {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.neptune > .header > img {
  width: 350px;
}
.neptune > .header > .left {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.neptune > .header > .left > .head {
  font-size: 75px;
  font-weight: 600;
  letter-spacing: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.neptune > .header > .left > .desc {
  width: 350px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.neptune > .touristHeader,
.neptune > .aboutHeader,
.neptune > .factsHeader {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 65px;
  font-weight: bold;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.neptune > .touristDesc,
.neptune > .aboutDesc,
.neptune > .factsDesc {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.neptune > .touristDesc > div,
.neptune > .aboutDesc > div {
  width: 600px;
  font-size: 22px;
  line-height: 32px;
}
.neptune > .touristDesc > img,
.neptune > .aboutDesc > img {
  width: 600px;
}

.neptune > .factsDesc > div {
  width: 1000px;
  margin-bottom: 100px;
  font-size: 22px;
  line-height: 32px;
}
.neptune > .factsDesc > div > li {
  margin-bottom: 10px;
}

.neptune > .boxes {
  display: grid;
  grid-template-columns: 400px 400px;
  align-items: flex-start;
  justify-content: center;
  margin-top: 70px;
  gap: 30px;
}
.neptune > .boxes > .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.neptune > .boxes > .box > .head {
  font-size: 26px;
  font-weight: 700;
}
.neptune > .boxes > .box > .desc {
  border: 2px solid white;
  padding: 20px 10px;
  font-size: 20px;
  line-height: 26px;
  height: 300px;
  display: flex;
  align-items: center;
  background-color: #44c6d454;
  /* color: rgb(203, 240, 255); */
}
