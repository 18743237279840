.team {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}

.team > .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #17171ba9;
  padding: 30px 75px;
  border-radius: 20px;
  gap: 20px;
}
.team > .container > .text {
  color: white;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.team > .container > .members {
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 30px;
  column-gap: 70px;
}
.team > .container > .members > .member {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team > .container > .members > .member > img {
  height: 200px;
  margin-bottom: 10px;
}
.team > .container > .members > .member > .name {
  color: white;
  font-weight: 500;
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
.team > .container > .members > .member > .role {
  color: white;
  font-weight: 700;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}
.team > .container > .members > .member > .insta {
  display: flex;
  gap: 2px;
  align-items: center;
  margin-top: 5px;
}
.team > .container > .members > .member > .insta > img {
  height: 15px;
}
.team > .container > .members > .member > .insta > div {
  color: white;
  font-weight: 600;
  font-size: 12px;
}
