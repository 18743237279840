.mercury {
  height: 100vh;
  width: 100vw;
  background-color: black;
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
  display: flex;
  flex-direction: column;
}
.mercury > .head {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.mercury > .head > .left {
  width: 500px;
}
.mercury > .head > .left > .header {
  position: relative;
  font-size: 35px;
  font-weight: 700;
  z-index: 1;
  top: 20px;
  width: fit-content;
  left: 30px;
  background-color: black;
  letter-spacing: 1.5px;
  font-family: "Raleway", sans-serif;
}
.mercury > .head > .left > .desc {
  border: 5px solid white;
  width: fit-content;
  padding: 20px 30px;
  font-size: 30px;
  font-weight: 400;
  word-spacing: 5px;
  letter-spacing: 1px;
  z-index: -1;
  font-family: "Raleway", sans-serif;
}
.mercury > .head > .left > .bottom {
  margin-top: 20px;
  font-family: "Raleway", sans-serif;
  font-size: 19.6px;
  font-weight: 400;
  line-height: 22px;
}
.mercury > .head > img {
  width: 300px;
}

.mercury > .desc {
  width: 800px;
  align-self: center;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
}

.mercury > .explore {
  margin-top: 100px;
  display: flex;
  width: 1000px;
  align-self: center;
  flex-direction: column;
}
.mercury > .explore > .header {
  font-size: 36px;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 10px;
}
.mercury > .explore > .bottom {
  display: flex;
  gap: 20px;
  align-items: center;
}
.mercury > .explore > .bottom > .desc {
  font-size: 20px;
  font-weight: 300;
  width: 600px;
}
.mercury > .explore > .bottom > img {
  width: 300px;
}

.mercury > .discover {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.mercury > .discover > .header {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 30px;
}

.mercury > .discover > .boxes {
  display: grid;
  grid-template-columns: 280px 280px 280px;
  column-gap: 40px;
  row-gap: 20px;
}
.mercury > .discover > .boxes > .box {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: black;
  text-align: center;
  gap: 10px;
}
.mercury > .discover > .boxes > .box > img {
  width: 250px;
}
.mercury > .discover > .boxes > .box > .title {
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  font-weight: 700;
}
.mercury > .discover > .boxes > .box > .desc {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.mercury > .facts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.mercury > .facts > .header {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 30px;
}
.mercury > .facts > .facts {
  display: grid;
  grid-template-columns: 280px 280px 280px;
  column-gap: 40px;
  row-gap: 20px;
  margin-bottom: 100px;
  align-items: flex-end;
}
.mercury > .facts > .facts > .fact {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
}
.mercury > .facts > .facts > .fact > .head {
  font-family: "Raleway", sans-serif;
  font-size: 28px;
  font-weight: 700;
}
.mercury > .facts > .facts > .fact > .body {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 500;
  background-color: white;
  color: black;
  padding: 20px;
}
