@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Russo+One:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
html,
body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background: rgba(180, 178, 178, 0.185);
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.322);
}
body {
  background: url("../Assets/1268180.jpg") no-repeat center;
  background-size: cover;
  /* background: radial-gradient(circle, rgb(19, 97, 128) 0%, rgb(0, 1, 39) 100%); */
  /* background: #04364a; */
}
iframe {
  width: 600px;
  height: 400px;
}
.sources {
  height: 100vh;
  width: 100vw;
  background: black;
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
}
