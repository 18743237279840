.venus {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(rgba(251, 186, 56, 255), rgb(255, 72, 0));
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
}
.venus > .header {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 400px;
  gap: 100px;
  margin-bottom: 100px;
}
.venus > .header > div > .text {
  align-self: flex-end;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 100px;
  font-weight: bold;
}
.venus > .header > div > .desc {
  font-size: 24px;
  width: 340px;
  text-align: center;
}

.venus > .touristHeader,
.venus > .aboutHeader,
.venus > .factsHeader {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.venus > .touristDesc,
.venus > .aboutDesc,
.venus > .factsDesc {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 150px;
}
.venus > .touristDesc > div,
.venus > .aboutDesc > div,
.venus > .factsDesc > div {
  width: 600px;
  font-size: 22px;
  line-height: 32px;
}
.venus > .factsDesc > img {
  width: 450px;
}
.venus > .touristDesc > img {
  width: 550px;
}
