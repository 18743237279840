.plan {
  height: 100vh;
  width: 100vw;
  background: url("../Assets/plan/bg.png") no-repeat center;
  background-size: cover;
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
}
.plan > .head {
  display: flex;
  justify-content: center;
  margin-top: 75px;
  font-size: 48px;
  font-weight: 700;
}
.plan > .setup {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.plan > .setup > .select {
  display: flex;
  flex-direction: column;
}
.plan > .setup > .select > .act {
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #777;
  font-weight: 600;
  font-size: 18px;
  width: 400px;
}
.plan > .setup > .select > .active {
  width: 400px;
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px 10px 0px 0px;
  color: #777;
  font-weight: 600;
  font-size: 18px;
}
.dropdown {
  height: 20px;
}
.dropdownrev {
  height: 20px;
  rotate: 180deg;
}
.plan > .setup > .select > .items {
  background-color: rgba(255, 255, 255, 0.89);
  color: black;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  border-radius: 0px 0px 10px 10px;
  z-index: 1;
}
.plan > .setup > .select > .items > .selectItem {
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}
.plan > .setup > .select > .items > .selectItem:hover {
  background-color: white;
}
.plan > .desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.plan > .desc > .data {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-left: 40px;
  /* width: 600px; */
}
.plan > .desc > .data > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.plan > .desc > .data > div > div {
  font-size: 28px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  gap: 5px;
}
.plan > .desc > .data > div > div > img {
  height: 30px;
}
.plan > .desc > .data > div > .description {
  font-size: 18px;
  font-weight: 500;
}
.plan > .desc > .route {
  width: 740px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.plan > .desc > .route > .head {
  font-size: 40px;
  font-weight: 800;
  margin-left: -10px;
}
.plan > .desc > .route > .routedata {
  font-size: 18px;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 5px;
  text-align: center;
}

.voyager {
  font-size: 18px;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 5px;
  text-align: center;
  word-wrap: break-word;
  flex-direction: column;
  /* word-break: break-all; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}
.voyager > .head {
  font-size: 30px;
  font-weight: 700;
}
.voyager > div {
  width: 700px;
}
