.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  gap: 5px;
}
.home > .container2 {
  display: flex;
  gap: 20px;
}
.home > .container2 > div > .head {
  text-decoration: none;
  color: white;
  font-weight: 700;
  font-size: 60px;
}

.home > .container2 > div > .desc {
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-size: 20px;
  width: 500px;
}
.home > .container {
  /* background-color: #17171ba9; */
  margin-top: 100px;
}
.home > .container > .head {
  margin-left: 40px;
  margin-top: 20px;
  color: white;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
}
.home > .container > .planets {
  display: flex;
  width: 100vw;
  overflow-x: auto;
  /* user-select: non e; */
  padding: 20px 20px 10px;
  /* background-color: #17171ba9; */
  gap: 20px;
}
.home > .container > .planets::-webkit-scrollbar {
  height: 6px;
}
.home > .container > .planets::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
.home > .container > .planets::-webkit-scrollbar-thumb {
  background: rgba(180, 178, 178, 0.185);
  border-radius: 20px;
}
.home > .container > .planets::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.322);
}
.home > .container > .planets > .planet {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  border: 2px solid white;
  padding: 35px 20px;
  background-color: #17171ba9;
}
.home > .container > .planets > .exploreItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  border: 2px solid white;
  padding: 20px 10px 10px;
  background-color: #17171ba9;
}
.home > .container > .planets > .planet > img {
  height: 150px;
}
.home > .container > .planets > .exploreItem > img {
  width: 300px;
  height: 200px;
}
.home > .container > .planets > .planet > .body {
  display: flex;
  flex-direction: column;
  width: 150px;
  gap: 5px;
}
.home > .container > .planets > .planet > .body > .head {
  color: white;
  font-weight: 700;
  font-size: 36px;
}
.home > .container > .planets > .exploreItem > .head {
  color: white;
  font-weight: 700;
  font-size: 36px;
  width: 330px;
  text-align: center;
}
.home > .container > .planets > .planet > .body > .desc {
  color: white;
  font-weight: 200;
  font-size: 14px;
}
